<ng-container [ngSwitch]="true">
    <!--all chat-->
    <div *ngSwitchCase="role !=ProjectRole.ITLegalChat && role != ProjectRole.patentBot">
        <div class="h1 text-4xl font-semibold mt-6 sm:mt-[20vh] sm:mb-16 flex gap-2">
            Hello, I’m {{projectData?.title!}}
        </div>
        <div >
            <p class="fmessage-p">
              It's AI time! Here it is safe to ask and chat about anything related to your line of work.
            </p>
            <p class="fmessage-p text-center">Join the future!</p>
        </div>
    </div>

    <!--Patent Chat-->
    <div *ngSwitchCase="role ==ProjectRole.patentBot" >
        <div class="h1 text-4xl font-semibold mt-6 sm:mt-[20vh] sm:mb-16 flex gap-2">
            Hello, I’m {{projectData?.title!}}
        </div>
        <div class="fmessage-patent">
            <br> <br>
            <p><b> What can you do here?​</b></p>
            <p><b> 1.Draft a patent idea -</b>  answer the questions to develop your idea and obtain an idea for a query to Questel.</p>
            <p><b> 2.Upload the table of relevant patents</b> from Questel and<b> receive freedom to operate</b> report for your proposed patent.​</p>
        </div>

    </div>

    <!-- IT Legal Chat-->
    <div *ngSwitchCase="role==ProjectRole.ITLegalChat" class="hebrew-font">
        <div class=" text-he text-4xl font-semibold mt-6 sm:mt-[20vh] sm:mb-16 flex gap-2">
            ברוכים לצ'אט מסמכים משפטיים של IT
          </div>
          <div *ngIf="role==ProjectRole.ITLegalChat" class="fmessage-patent">
            ניתן לשאול שאלות בנוגע לכתב ההגנה, כתב התביעה, תצהירים  וחוות דעת המומחים הנוגעים לתיק המשפטי 
          </div>
    </div>
</ng-container>