export enum ProjectRole{
chatGPT="Ap-icl-chatGPT",
procedureBots="Ap-icl-procedurebot",
researchBot="Ap-icl-researchBot",
rotemEngineeringBot="AP-ICL-RotemEngineeringBot",
agronomistBot ="AP-ICL-AgronomistBot",
patentBot = "AP-ICL-PatentBot",
fieldTrailChat = "AP-ICL-FieldTrailChat",
internalAuditChat = "AP-ICL-Internal-Audit",
ITLegalChat = "AP-ICL-LegalChat",
theMind = "AP-ICL-TheMind"
}