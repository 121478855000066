export enum BaseUri{
  procedureBot = "",
  researchBot = "",
  chatGpt = "",
  rotemEngineeringBot = "",
  agronomistBot = "",
  patentBot = "", 
  // procedureBot = "procedureBot/",
  // researchBot = "researchBot/",
  // chatGpt = "chatGpt/",
  // rotemEngineeringBot = "rotemEngineeringBot/",
  // agronomistBot = "agronomyBot/",
  // patentBot = "singleAuth/",
}