import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectRole } from 'src/app/classes/enum/role';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { ProjsectsDataService } from 'src/app/services/projectsData.service';
import { RolesService } from 'src/app/services/roles.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-postauth',
  templateUrl: './postauth.component.html',
  styleUrls: ['./postauth.component.scss'],
})
export class PostauthComponent implements OnInit {
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private projectDataService: ProjsectsDataService
  ) {}
  async isLoggedIn(envConfig): Promise<boolean> {
    this.authorizationService.initAuthConfig(envConfig);
    return await this.authorizationService.isLoggedIn(envConfig.clientId);
  }

  async ngOnInit(): Promise<void> {
    let envConfig =
      environment.ProjectsEnvironment.portalEnvironment.authConfig;
    if (await this.isLoggedIn(envConfig)) {
      try {
        this.router.navigateByUrl('launchpad');
      } catch (error) {
        console.error(error);
      }
    } else {
      await this.authorizationService
        .login('postauth', envConfig.clientId)
        .then(async () => {
          if (await this.authorizationService.isLoggedIn(envConfig.clientId))
            this.router.navigateByUrl('launchpad');
        });
    }
  }
}
