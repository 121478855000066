import { Injectable } from '@angular/core';
import { ProjectRole } from '../classes/enum/role';
import { RouteInfo } from '../classes/enum/interfaces/RoutInfo';
@Injectable({
  providedIn: 'root',
})
export class ProjectsDataService {
  projectRole:ProjectRole | undefined;
  allRols: any;
  activateRoute: any;
  constructor() {}
  projectList: RouteInfo[] = [
    {      
      path: '/procedures-bots',
      title: 'Procedure Chat',
      version: '1.1.0',
      icon: 'procidure-bots-icon.png',
      environment:'procedureBotsEnvirpnment',
      rolesDisplay: ProjectRole.procedureBots,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'message',summarize:'', getResearchBooks:''},
      iconChatAssistent:'assets/images/procidure-bots-icon.png',
      logoChat:'Procedures',
      newButton:'New Chat',
      stopButton:'Stop Generating', 
        exapmles:[
        {title:'Finance',content:'How many approvals are required for a purchase order request totaling $50,001 in ICL?'},
        {title:'Travel',content:'What documentation is necessary for employees to submit travel expense reimbursements?'},
        {title:'Safety',content:'How often are safety drills conducted to ensure everyone is prepared?'},
        {title:'CAPEX (Capital Expenditure)',content:'How many levels of authorization are needed for a capital expenditure project worth $50,000, and what documentation is required at each stage of approval?'}
    ]
    },
    {
      path: '/chat-message',
      title: 'ICL ChatGPT',
      version: '1.0.0',
      icon: 'chatgpt-icon.png',
      environment:'chatGPTEnvironment',
      rolesDisplay: ProjectRole.chatGPT,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'',deleteLastMessage:'',summarize:'', getResearchBooks:''},
      iconChatAssistent:'assets/images/dark/assistrant.png',
      logoChat:'ChatGPT',
      newButton:'New Chat',
      stopButton:'Stop Generating', 
      exapmles:[
        {title:'Management',content:'As an IT manager, describe ways to prioritize tasks?'},
        {title:'Programming',content:'please write a python code for a "Hello world" script.'},
        {title:'Communication',content:'How can we improve our email communication within the department?'},
        {title:'Time Management',content:'What are some effective ways to manage time during meetings?'}
      ]
     },
     {
      path: '/research-bot',
      title: 'Research Chat',
      version: '1.0.0',
      icon: 'research-bots-icon.png',
      environment:'researchBotEnvirpnment',
      rolesDisplay: ProjectRole.researchBot,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      iconChatAssistent:'assets/images/research-bots-icon.png',
      logoChat:'Researches',
      newButton:'New Chat',
      stopButton:'Stop Generating', 
      exapmles:[
        {title:'solvent extraction',content:'What solvents can be used to separate CaBr2 from aqueous solutions by solvent extraction?'},
        {title:'flame retardants',content:'Which flame retardants were tested for rigid or flexible polyurethanes?'},
        {title:'Ion exchange technology with sodium',content:'What works were done on ion exchange technology that involve sodium (removal or extraction)?'},
        {title:'Sodium : removal or extraction',content:'What is the typical temperature range for bromination of pentabromobenzyl monoacrylate?'}
      ],
      tableTitle:{Source:'Source list:',Summarize:'Summarize',Focus:'Focus',Name:'Name', BU:'BU', Author:'Author'}

     },
     {
      path: '/rotem-engineering-bot',
      title: 'Rotem Engineering Chat',
      version: '1.1.2',
      icon: 'rotem-icon.png',
      environment:'rotemEngineeringBotEnvirpnment',
      rolesDisplay: ProjectRole.rotemEngineeringBot,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:'message/getResearchBooks'},
      iconChatAssistent:'assets/images/research-bots-icon.png',
      logoChat:'Rotem Engineering',
      newButton:'נושא חדש',
      stopButton:'עצירת יצירת התשובה', 
      exapmles:[
        {title:'סוגי פלדות',content:'מה ההבדל בין פלדה SS316 לבין G30?'},
        {title:'מערכות הנעה הידראולוגיות',content:'מהי מערכת הנעה הידראולית?'},
        {title:'סוגי ממסרות ברותם',content:'איזה שמן מומלץ להשתמש בממסרות?'},
        {title:'מסועי סרט',content:'תסביר לי סיבות אפשריות לסטיות של מסועי סרט.'}     
      ],
      
tableTitle:{Source:'אולי יעניין אותך גם?',Summarize:'סיכום',Focus:'פוקוס',Name:'שם', BU:'חטיבה', Author:'עורך'}
     },
     {
      path: '/agronomist-bot',
      title: 'Agronomist Research Chat ',
      version: '1.0.0',
      icon: 'agronomis-icon.png',
      environment:'agronomistBotEnvirpnment',
      rolesDisplay: ProjectRole.agronomistBot,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      iconChatAssistent:'assets/images/research-bots-icon.png',
      logoChat:'Agronomist research',
      newButton:'New Chat',
      stopButton:'Stop Generating', 
      exapmles:[
        {title:'Phosphogypsum',content:'. What is the heavy metals and radioactive elements concentration in phosphogypsum?'},
        {title:'Si in plants',content:'What is the role of silicon in plants?'},
        {title:'Biostimulants in agriculture',content:'What is the contribution of biostimulants to crop production?'},
        {title:'Allowed metal ',content:'What are the allowed heavy metal concentrations in fruits and vegetables? '}
      ],
      tableTitle:{Source:'Source list:',Summarize:'Summarize',Focus:'Focus',Name:'Name', BU:'BU', Author:'Author'}

     },
     {
      path: '/patent-bot',
      title: 'Patent Chat',
      version: '1.1.0',
      icon: 'patent-caht-icon.png',
      environment:'patentBotEnvirpnment',
      rolesDisplay: ProjectRole.patentBot,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      iconChatAssistent:'assets/images/patent-caht-icon.png',
      logoChat:'Patent chat',
      newButton:'New Project',
      stopButton:'Stop Generating', 
      exapmles:[
        {title:'Magnesium',content:'Offer me an idea about developing a magnesium patent'},
        {title:'Lithium Bromide',content:'Offer me an idea about developing a patent for the preparation of lithium bromide'},
        {title:'Kieserite and Gran',content:'Offer me an idea about developing a patent on Kieserite and Gran'},
        {title:'Textile recycling',content:'Offer me an idea about developing a patent for a textile cycle'}
      ]

     },
     {
      path: '/field-trial-chat',
      title: 'Field Trial Chat',
      version: '1.0.0',
      icon: 'field-trials-caht-icon.png',
      environment:'fieldTrailChatEnvirpnment',
      rolesDisplay: ProjectRole.fieldTrailChat,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      iconChatAssistent:'assets/images/field-trials-caht-icon.png',
      logoChat:'Field Trial Chat',
      newButton:'New Chat',
      stopButton:'Stop Generating', 
      exapmles:[
        {title:'Effect of Biostimulants',content:'What is the effect of plant biostimulants on crop yield?'},
        {title:'Chlorine Benefits',content:'What are the advantages of chlorine in fertilizers?'},
        {title:'Micronutrient',content:'What is the role of micronutrients in fruit quality?'},
        {title:'Nutrient Interactions',content:'Which nutrients have a synergetic or inhibiting effect?'}
      ],
      tableTitle:{Source:'Source list:',Summarize:'Summarize',Focus:'Focus',Name:'Name', BU:'BU', Author:'Author'}
     },
     {
      path: '/internal-audit-chat',
      title: 'Internal Audit AI Chat',
      version: '1.0.0',
      icon: 'internal-audit-chat-icon.png',
      environment:'InternalAuditChatEnvirpnment',
      rolesDisplay: ProjectRole.internalAuditChat,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      iconChatAssistent:'assets/images/research-bots-icon.png',
      logoChat:'Internal Audit AI Chat',
      newButton:'New Chat',
      stopButton:'Stop Generating', 
      exapmles:[
        {title:'solvent extraction',content:'What solvents can be used to separate CaBr2 from aqueous solutions by solvent extraction?'},
        {title:'flame retardants',content:'Which flame retardants were tested for rigid or flexible polyurethanes?'},
        {title:'Ion exchange technology with sodium',content:'What works were done on ion exchange technology that involve sodium (removal or extraction)?'},
        {title:'Sodium : removal or extraction',content:'What is the typical temperature range for bromination of pentabromobenzyl monoacrylate?'}
      ],
      tableTitle:{Source:'Source list:',Summarize:'Summarize',Focus:'Focus',Name:'Name', BU:'BU', Author:'Author'}
     },
     {
      path: '/it-legal-chat',
      title: 'IT Legal Chat',
      version: '1.0.0',
      icon: 'it-legal-chat-icon.png',
      environment:'ITLegalChatEnvirpnment',
      rolesDisplay: ProjectRole.ITLegalChat,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      iconChatAssistent:'assets/images/research-bots-icon.png',
      logoChat:'IT Legal Chat',
      newButton:'נושא חדש',
      stopButton:'עצירת יצירת התשובה', 
      exapmles:[
        {title:'כתב התביעה',content:'מהם הטיעונים העיקריים בכתב התביעה?'},
        {title:'כתב ההגנה',content:'מהם הטיעונים העיקריים בכתב ההגנה?'},
        {title:'האנשים העיקריים שמוזכרים בתביעה',content:'מי הם האנשים העיקריים ובעלי התפקידים שמוזכרים בתביעה משני האירגונים?'},
        {title:'הפרת הסכם',content:'האם ישנן הפרות של ההסכם שיש הסכמה של שני הצדדים לגביהן?'}
      ],
      tableTitle:{Source:'אלו המקורות עליהם התבססנו בתשובה, תוכל לשנות את בחירת המקורות הרלוונטיים עבור המשך השיחה ',Summarize:'סיכום',Focus:'פוקוס',Name:'שם', BU:'חטיבה', Author:'עורך'}
     },
  ];
  getAllData() {
    return this.projectList;
  }
  findProjectByProp(propName: string, propValue: any): RouteInfo | undefined {
    return this.projectList.find((project) => project[propName] === propValue);
    }
    setRols(roles){
      this.allRols=roles;
    }
    getAllRols(){
      return this.allRols;
    }
  setProjectRole(role:ProjectRole){
    this.projectRole=role;
  }
  getProjectRole(){
    return this.projectRole;
  }
  setActivateRoute(route){
    this.activateRoute=route;
  }
  getActivateRoute(){
    return this.activateRoute
  }
}
