<div [ngClass]="{ 'app-side-bar-show': openSidebar }">
  <div class="logo">
    <img *ngIf="isDarkModeCheck === false" src="../../../assets/icl-Login.svg" />
    <img *ngIf="isDarkModeCheck" src="../../../assets/icl-Login-w.svg" />
  </div>
  <div class="sidbar-rap">
    <div class="logo-rap">
      <div class="go-back-rap">
        <div (click)="goBack()">
          <img class="goBack" *ngIf="isDarkModeCheck === false"
            src="../../../assets/images/light/icons8-left-arrow-light.png" />
          <img class="goBack" *ngIf="isDarkModeCheck" src="../../../assets/images/dark/icons8-left-arrow-dark.png" />
        </div>
        <p>{{this.projectData?.logoChat}}</p><p *ngIf="projectRole==ProjectRole.procedureBots">Beta</p>
      </div>

      <button mat-icon-button [matMenuTriggerFor]="menu" class="open-sidbar btn-rm"
        aria-label="Example icon-button with a menu">
        <img src="../../../assets/icons/dot-horizontal.svg" width="24" height="24" />
      </button>
      <mat-menu id="matMenu-m" #menu="matMenu">
        <button mat-menu-item (click)="openSid()">
          <span>History</span>
        </button>
        <button mat-menu-item (click)="openHelp()">
          <span>Help & FAQ</span>
        </button>
        <button mat-menu-item (click)="openPrivacy()">
          <span>Privacy</span>
        </button>

        <button class="btn-rm btn-model" mat-menu-item>
          <div class="img-text">
            <span class="text-btn">
              <!-- <mat-slide-toggle (change)="toggleDarkMode()"> -->
              Use dark theme
              <!-- </mat-slide-toggle> -->
              <label class="switch">
                <input type="checkbox" id="darkModeSwitch" (change)="toggleDarkMode()" [(ngModel)]="darkModeToggle" />
                <span class="slider round"></span>
              </label>
            </span>
          </div>
        </button>
        <button class="btn-rm btn-model" (click)="logout()" mat-menu-item>
          <div class="img-text">
            <span class="text-btn">Log out
              <img [src]="
                isDarkModeCheck
                  ? '../../../assets/icons/logout-w.svg'
                  : '../../../assets/icons/logout.svg'
              " width="20" height="20" />
            </span>
          </div>
        </button>

        <button class="title-mes" mat-menu-item *ngIf="sideChatBox && sideChatBox.size > 0"
          (click)="$event.stopPropagation()">
          <!-- <span *ngIf="isEditing !== activeChat.id">{{ activeChat?.title }}</span> -->
          <!-- <input class="msg-container__side__input" *ngIf="isEditing == activeChat.id" [(ngModel)]="activeChat.title"
            placeholder="Type a message..." width="150px" (focusout)="setChatTitle(activeChat)" /> -->
        </button>
        <button mat-menu-item *ngIf="sideChatBox && sideChatBox.size > 0" (click)="editChat(activeChat)"
          (click)="$event.stopPropagation()">
          <span class="img-text">
            edit
            <img [src]="
            isDarkModeCheck
              ? '../../../assets/icons/Edit-w.svg'
              : '../../../assets/icons/Edit.svg'
          " width="18" height="18" />
          </span>
        </button>
        <button mat-menu-item *ngIf="sideChatBox && sideChatBox.size > 0" (click)="removeChat(activeChat)">
          <span class="img-text text-red">delete
            <img src="../../../assets/icons/trash-r.svg" width="18" height="18" />
          </span>
        </button>
      </mat-menu>
    </div>
    <div class="new_chat_container" (click)="setActiveNewChatBtn()">
      <!-- <button [ngClass]="{
          new_chat_container__btn_new_chat:
            !newChat && isDarkModeCheck === false,
          new_chat_container__btn_new_chat_dark: !newChat && isDarkModeCheck,
          new_chat_container__btn_new_chat_active: newChat
        }"> -->
      <button [ngClass]="{
          new_chat_container__btn_new_chat: isDarkModeCheck === false,
          new_chat_container__btn_new_chat_dark: isDarkModeCheck,
          new_chat_container__btn_new_chat_active: true
          }">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"
          *ngIf="isDarkModeCheck">
          <path d="M7.35955 1.14685V13.853" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13.7183 7.50006H1" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="black"
          *ngIf="isDarkModeCheck === false">
          <path d="M7.35955 1.14685V13.853" stroke="#002136" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13.7183 7.50006H1" stroke="#002136" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div *ngIf="isDarkModeCheck === false" style="color: #002136; font-size: 16px">
          {{projectData?.newButton}}
        </div>
        <div *ngIf="isDarkModeCheck" style="color: white; font-size: 16px">
          {{projectData?.newButton}}
        </div>
      </button>
    </div>



    <!-- <button  (click)="openSid()" data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="open-sidbar btn-rm">
      <img
      src='../../../assets/icons/dot-horizontal.svg'
      width="24"
      height="24"
    />
    </button> -->
  </div>

  <!-- <button mat-button (click)="openDialog()">Launch dialog</button> -->

  <div class="body">
    <div class="msg-container" *ngIf="sideChatBox && sideChatBox.size > 0">
      <img class="img-his" (click)="openSid()" [src]="
        isDarkModeCheck
          ? '../../../assets/icons/x-square-contained-w.svg'
          : '../../../assets/icons/x-square-contained.svg'
      " width="24" height="24" />
      <p class="p-his">History</p>
      <div class="msg-container__side" *ngFor="let box of sideChatBox | keyvalue : originalOrder">
        <span style="color: #797c82; font-weight: 400" *ngIf="isDarkModeCheck === false">
          {{ box.key }}
        </span>
        <span style="color: white; font-weight: 400" *ngIf="isDarkModeCheck">
          {{ box.key }}
        </span>
        <button *ngFor="let chat of box.value; let i = index" [ngClass]="{
            'msg-container_side-chat':
              (activeChat?.id !== chat.id || newChat) &&
              isDarkModeCheck === false,
            'msg-container_side-chat_active':
              activeChat?.id === chat.id &&
              !newChat &&
              isDarkModeCheck === false,
            'msg-container_side-chat_dark':
              (activeChat?.id !== chat.id || newChat) && isDarkModeCheck,
            'msg-container_side-chat_active_dark':
              activeChat?.id === chat.id && !newChat && isDarkModeCheck
          }" (click)="setActiveChat(i, box.key)">
          <!-- msg icons -->
          <svg *ngIf="isDarkModeCheck === false" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
            viewBox="0 0 20 20" fill="none">
            <path
              d="M6.57761 16.1741L9.7988 12.9529H14.1741C15.2786 12.9529 16.1741 12.0574 16.1741 10.9529V5.82617C16.1741 4.7216 15.2786 3.82617 14.1741 3.82617H5.82617C4.7216 3.82617 3.82617 4.7216 3.82617 5.82617V10.9529C3.82617 12.0574 4.7216 12.9529 5.82617 12.9529H6.57761V16.1741Z"
              stroke="black" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <svg *ngIf="isDarkMode() && isDarkModeCheck ===true" xmlns="http://www.w3.org/2000/svg" width="40" height="40"
            viewBox="0 0 20 20" fill="none">
            <path
              d="M6.57761 16.1739L9.7988 12.9528H14.1741C15.2786 12.9528 16.1741 12.0573 16.1741 10.9528V5.82605C16.1741 4.72148 15.2786 3.82605 14.1741 3.82605H5.82617C4.7216 3.82605 3.82617 4.72148 3.82617 5.82605V10.9528C3.82617 12.0573 4.7216 12.9528 5.82617 12.9528H6.57761V16.1739Z"
              stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <!-- title of the chat -->
          <span class="t-mob" *ngIf="isEditing != chat.id && isDarkModeCheck === false"><dfn [attr.title]="chat?.title?.slice(0, 2)">{{ chat?.title?.split("")?.slice(0, 2) }}</dfn></span>
          <span style="
              font-size: 16px;
              font-weight: 400;
              color: #002136;
              right: 7px;
              position: relative;
              text-overflow: ellipsis;
              text-align: start;
            " *ngIf="isEditing != chat.id && isDarkModeCheck === false"><dfn [attr.title]="chat.title">{{ chat.title }}</dfn></span>
          <span class="t-mob" *ngIf="
              (isEditing != chat.id &&
                isDarkModeCheck) ||
              (newChat == 1 && isDarkModeCheck)
            "><dfn [attr.title]="chat?.title?.slice(0, 2)">{{ chat?.title?.split("")?.slice(0, 2) }}</dfn></span>
          <span style="
              font-size: 16px;
              font-weight: 400;
              color: white;
              right: 7px;
              position: relative;
              text-overflow: ellipsis;
              text-align: start;
            " *ngIf="
              (isEditing != chat.id &&
                isDarkModeCheck) ||
              (newChat == 1 && isDarkModeCheck)
            "><dfn [attr.title]="chat.title">{{ chat.title }}</dfn></span>
          <!-- input for new title -->
          <input class="msg-container__side__input" *ngIf="isEditing == chat.id" [(ngModel)]="chat.title"
            placeholder="Type a message..." width="150px" />
          <!-- ligt theme -->
          <div class="bottom_section" *ngIf="isDarkModeCheck === false">
            <!-- bin icon -->
            <svg (click)="removeChat(chat)" *ngIf="
                isEditing != chat.id &&
                activeChat &&
                activeChat.id == chat.id &&
                newChat == 0
              " xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M4.68311 5.50343H16.0738M8.95461 12.6226V8.3511M11.8023 12.6226V8.3511M13.2261 15.4703H7.53077C6.74441 15.4703 6.10694 14.8328 6.10694 14.0464V6.21534C6.10694 5.82216 6.42568 5.50343 6.81886 5.50343H13.938C14.3312 5.50343 14.6499 5.82216 14.6499 6.21534V14.0464C14.6499 14.8328 14.0125 15.4703 13.2261 15.4703ZM8.95461 5.50343H11.8023C12.1955 5.50343 12.5142 5.18469 12.5142 4.79151V4.07959C12.5142 3.68641 12.1955 3.36768 11.8023 3.36768H8.95461C8.56143 3.36768 8.24269 3.68641 8.24269 4.07959V4.79151C8.24269 5.18469 8.56143 5.50343 8.95461 5.50343Z"
                stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <!-- edit icon -->
            <svg *ngIf="
                isEditing != chat.id &&
                activeChat &&
                activeChat.id == chat.id &&
                newChat == 0
              " (click)="editChat(chat)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
              fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.1049 12.7979H7.87402C7.63252 12.7979 7.43652 12.6019 7.43652 12.3604C7.43652 12.1189 7.63252 11.9229 7.87402 11.9229H12.1049C12.3464 11.9229 12.5424 12.1189 12.5424 12.3604C12.5424 12.6019 12.3464 12.7979 12.1049 12.7979Z"
                fill="white" />
              <mask id="mask0_25_551" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="1" y="1" width="11"
                height="12">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16699 1.75H11.189V12.7981H1.16699V1.75Z"
                  fill="white" />
              </mask>
              <g mask="url(#mask0_25_551)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.64799 2.92637L2.1559 9.79513C2.05615 9.91996 2.0194 10.081 2.05615 10.2355L2.4534 11.9185L4.22615 11.8963C4.39474 11.8945 4.55049 11.8193 4.65374 11.691C6.53032 9.34304 10.1079 4.86654 10.2094 4.73529C10.3051 4.58013 10.3424 4.36079 10.2922 4.14963C10.2409 3.93321 10.1062 3.74946 9.9119 3.63221C9.87049 3.60362 8.88757 2.84062 8.85723 2.81671C8.4874 2.52037 7.94782 2.57171 7.64799 2.92637ZM2.10807 12.7981C1.90565 12.7981 1.72949 12.6593 1.68224 12.4615L1.20449 10.4368C1.1059 10.0174 1.2039 9.58454 1.47282 9.24854L6.96782 2.37571C6.97015 2.37337 6.9719 2.37046 6.97424 2.36812C7.57682 1.64771 8.66649 1.54154 9.40149 2.13129C9.43065 2.15404 10.4066 2.91237 10.4066 2.91237C10.7612 3.12354 11.0383 3.50096 11.1433 3.94779C11.2477 4.38996 11.1719 4.84612 10.9287 5.23171C10.9106 5.26029 10.8948 5.28479 5.33682 12.2381C5.06907 12.5718 4.66774 12.766 4.23665 12.7713L2.1139 12.7981H2.10807Z"
                  fill="white" />
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.46399 6.81613C9.37065 6.81613 9.27732 6.78638 9.1974 6.72572L6.01707 4.28272C5.82574 4.13572 5.78957 3.86155 5.93657 3.66905C6.08415 3.47772 6.35832 3.44213 6.55024 3.58913L9.73115 6.03155C9.92249 6.17855 9.95865 6.4533 9.81107 6.64522C9.72532 6.75722 9.59524 6.81613 9.46399 6.81613Z"
                fill="white" />
            </svg>
            <!-- approve icon -->
            <svg *ngIf="isEditing == chat.id" (click)="setChatTitle(chat)" xmlns="http://www.w3.org/2000/svg" width="18"
              height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M11.7929 7.20711L8 11L6.70711 9.70711M9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1Z"
                stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>

          <!-- dark theme -->
          <div class="bottom_section" *ngIf="isDarkModeCheck">
            <!-- bin icon -->
            <svg (click)="removeChat(chat)" *ngIf="
                isEditing != chat.id &&
                activeChat &&
                activeChat.id === chat.id &&
                newChat == 0
              " xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M4.68311 5.50349H16.0738M8.95461 12.6227V8.35116M11.8023 12.6227V8.35116M13.2261 15.4703H7.53077C6.74441 15.4703 6.10694 14.8329 6.10694 14.0465V6.2154C6.10694 5.82222 6.42568 5.50349 6.81886 5.50349H13.938C14.3312 5.50349 14.6499 5.82222 14.6499 6.2154V14.0465C14.6499 14.8329 14.0125 15.4703 13.2261 15.4703ZM8.95461 5.50349H11.8023C12.1955 5.50349 12.5142 5.18475 12.5142 4.79157V4.07965C12.5142 3.68647 12.1955 3.36774 11.8023 3.36774H8.95461C8.56143 3.36774 8.24269 3.68647 8.24269 4.07965V4.79157C8.24269 5.18475 8.56143 5.50349 8.95461 5.50349Z"
                stroke="#002136" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <!-- edit icon -->

            <svg *ngIf="
                isEditing != chat.id &&
                activeChat &&
                activeChat.id === chat.id &&
                newChat == 0
              " (click)="editChat(chat)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
              fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.1049 12.7982H7.87402C7.63252 12.7982 7.43652 12.6022 7.43652 12.3607C7.43652 12.1192 7.63252 11.9232 7.87402 11.9232H12.1049C12.3464 11.9232 12.5424 12.1192 12.5424 12.3607C12.5424 12.6022 12.3464 12.7982 12.1049 12.7982Z"
                fill="#002136" />
              <mask id="mask0_26_5080" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="1" y="1" width="11"
                height="12">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16699 1.75012H11.189V12.7982H1.16699V1.75012Z"
                  fill="white" />
              </mask>
              <g mask="url(#mask0_26_5080)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.64799 2.9265L2.1559 9.79525C2.05615 9.92008 2.0194 10.0811 2.05615 10.2357L2.4534 11.9186L4.22615 11.8964C4.39474 11.8947 4.55049 11.8194 4.65374 11.6911C6.53032 9.34316 10.1079 4.86666 10.2094 4.73541C10.3051 4.58025 10.3424 4.36091 10.2922 4.14975C10.2409 3.93333 10.1062 3.74958 9.9119 3.63233C9.87049 3.60375 8.88757 2.84075 8.85723 2.81683C8.4874 2.5205 7.94782 2.57183 7.64799 2.9265ZM2.10807 12.7982C1.90565 12.7982 1.72949 12.6594 1.68224 12.4617L1.20449 10.4369C1.1059 10.0175 1.2039 9.58466 1.47282 9.24866L6.96782 2.37583C6.97015 2.3735 6.9719 2.37058 6.97424 2.36825C7.57682 1.64783 8.66649 1.54166 9.40149 2.13141C9.43065 2.15416 10.4066 2.9125 10.4066 2.9125C10.7612 3.12366 11.0383 3.50108 11.1433 3.94791C11.2477 4.39008 11.1719 4.84625 10.9287 5.23183C10.9106 5.26041 10.8948 5.28491 5.33682 12.2382C5.06907 12.5719 4.66774 12.7662 4.23665 12.7714L2.1139 12.7982H2.10807Z"
                  fill="#002136" />
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.46399 6.81619C9.37065 6.81619 9.27732 6.78644 9.1974 6.72578L6.01707 4.28278C5.82574 4.13578 5.78957 3.86161 5.93657 3.66911C6.08415 3.47778 6.35832 3.44219 6.55024 3.58919L9.73115 6.03161C9.92249 6.17861 9.95865 6.45336 9.81107 6.64528C9.72532 6.75728 9.59524 6.81619 9.46399 6.81619Z"
                fill="#002136" />
            </svg>
            <!-- approve icon -->
            <svg *ngIf="isEditing == chat.id" (click)="setChatTitle(chat)" xmlns="http://www.w3.org/2000/svg" width="18"
              height="18" viewBox="0 0 18 18" fill="none">
              <path
                d="M11.7929 7.20711L8 11L6.70711 9.70711M9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1Z"
                stroke="white" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </button>
      </div>
    </div>
    <div class="setting-rap toggleButtonSetting">
      <div class="model-setting " [ngClass]="{ top: (projectRole == ProjectRole.researchBot||projectRole == ProjectRole.rotemEngineeringBot||projectRole == ProjectRole.agronomistBot)}" *ngIf="openSetting">
        <div *ngIf="projectRole == ProjectRole.researchBot||projectRole == ProjectRole.rotemEngineeringBot||projectRole == ProjectRole.agronomistBot ||projectRole == ProjectRole.fieldTrailChat" class="top model-sc btn-model ">
          <div class="text-btn">Number of Sources to return </div>
          <input type="number" [(ngModel)]="numberOfResearch" (change)="saveToLocalStorage()" max="50">
        </div>
        <div class="model-sc">
          <button class="btn-rm btn-model">
            <div class="img-text">
              <img [src]="isDarkModeCheck? '../../../assets/icons/moon-w.svg' : '../../../assets/icons/moon.svg'"
                width="24" height="24" />
              <div class="text-btn">Use dark theme</div>
            </div>
            <label class="switch">
              <input type="checkbox" id="darkModeSwitch" (change)="toggleDarkMode()" [(ngModel)]="darkModeToggle" />
              <span class="slider round"></span>
            </label>
          </button>
        </div>
        <div class="model-sc">
          <button class="btn-rm btn-model" (click)="logout()">
            <div class="img-text">
              <img *ngIf="isDarkModeCheck === false" src="../../../assets/icons/logout.svg" width="24" height="24" />
              <img *ngIf="isDarkModeCheck" src="../../../assets/icons/logout-w.svg" width="24" height="24" />
              <div class="text-btn">Log out</div>
            </div>
          </button>
        </div>
      </div>
      <button id="setting-info" class="btn-rm btn-ssetting" (click)="setOpenSetting()" mat-button>
        <!-- #toggleButtonSetting -->
        <div class="toggleButtonSetting setting" [style.background-color]="
            openSetting
              ? !isDarkModeCheck
                ? '#EFEFEF'
                : 'rgba(255, 255, 255, 0.20)'
              : ''
          ">
          <div class="name">
            <div class="name-c">{{ this.firstLetterName }}</div>
            <div>{{ this.userName }}</div>
          </div>
          <!-- <img [src]="
              !isDarkModeCheck
                ? '../../../assets/icons/dot-horizontal.svg'
                : '../../../assets/icons/dot-horizontal-w.svg'
            " width="24" height="24" /> -->
        </div>
      </button>
    </div>
  </div>
</div>