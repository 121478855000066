// typewriter.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typewriter'
})
export class typewriter implements PipeTransform {
  transform(text: string, index: number): string {
    return text.slice(0, index);
  }
}