import { Component, DoCheck, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { Router } from '@angular/router';
import { DarkModeService } from 'src/app/services/dark-mode.service';
import { ChatService } from '../../services/chat-service.service';
import Chat from '../../interfaces/chat';
import { environment } from 'src/environments/environment';
import { ProjsectsDataService } from 'src/app/services/projectsData.service';
import { RouteInfo } from 'src/app/classes/enum/interfaces/RoutInfo';
import { ProjectRole } from 'src/app/classes/enum/role';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  openSetting: boolean = false;
  userName: string = '';
  firstLetterName: string = '';
  isDarkModeCheck: boolean | null = this.darkModeService.isDarkMode && this.router.url != '/';
  sideChatBox: Map<string, Chat[]> = new Map<string, Chat[]>();
  chats: Chat[] = [];
  newChat: number = 0;
  darkMode: string = '';
  darkModeToggle: boolean = false;
  activeChat: any;
  endScroll: boolean = false;
  oldTitle: string = '';
  isEditing: number = -1;
  isNewChat: boolean = false;
  chatIDrm: number | null = null;
  openSidebar: boolean = false;
  projectRole: string | null;
  projectData: RouteInfo | undefined;
  numberOfResearch: number = 10;
  ProjectRole=ProjectRole
  @ViewChild('toggleButtonSetting') toggleButtonSetting!: ElementRef;


  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private darkModeService: DarkModeService,
    private chatService: ChatService,
    private projectDataService: ProjsectsDataService
  ) {
    this.projectRole = localStorage.getItem('rolesDisplay');
    this.projectData = this.projectDataService.findProjectByProp('rolesDisplay', this.projectRole)
    this.changeTitle()
  }

  async ngOnInit(): Promise<void> {
    if (await this.authorizationService.isLoggedInNewClientId()) {
      this.isDarkModeCheck = this.darkModeService.isDarkMode && this.router.url != '/';
      this.userName = this.authorizationService.getUserName();
      this.firstLetterName = this.userName ? this.userName[0] : "";
      this.darkMode = window.localStorage.getItem('darkMode') as any;
      this.setAllUsersChats()
      this.chatService.isNewChat$.subscribe((isNewChat) => {
        this.isNewChat = isNewChat;
      });

      this.chatService.messageres.subscribe(async (newData) => {
        if (newData.title && this.isNewChat) {
          newData.createdAt = newData.created_at;
          // delete newData.created_at;
          newData.updatedAt = newData.updated_at;
          // delete newData.updated_at;
          this.newChat = 0;
          this.chats.push(newData)
          this.sideChatBox = await this.mapArrayByCreatedAt(this.chats);
          this.activeChat = this.chats[0];
        }
        if (newData.status == 'failed' && this.isNewChat && newData.conversation?.title) {
          newData.conversation.createdAt = newData.conversation.created_at;
          // delete newData.conversation.created_at;
          newData.conversation.updatedAt = newData.conversation.updated_at;
          // delete newData.conversation.updated_at;
          this.newChat = 0;
          this.chats.push(newData.conversation)
          this.sideChatBox = await this.mapArrayByCreatedAt(this.chats);
          this.activeChat = this.chats[0];
          this.isNewChat = false
        }
      });
    } else {
      this.router.navigateByUrl('splash');
    }

  }
  goBack() {
    this.router.navigateByUrl('postauth');
  }
  openSid() {
    this.openSidebar = !this.openSidebar
  }


  setAllUsersChats() {
    // @ts-ignore

    this.chatService.getAllUsersChats(this.projectData.serverPath.getAllUsersChats).subscribe(
      async (response: any) => {
        // if (this.projectRole != ProjectRole.chatGPT)
          response = response.filter(obj=>obj.title!="error").map(obj => {
            obj.createdAt = obj.created_at;
            delete obj.created_at;
            obj.updatedAt = obj.updated_at;
            delete obj.updated_at;
            return obj;
          });
        this.sideChatBox = await this.mapArrayByCreatedAt(response);
        this.chats = response;

        //  if (!this.activeChat && this.chats && this.chats.length > 0) {
        //   // this.chatService.setActiveChat(`message/${this.chats[0].id}`)
        //   // this.activeChat = this.chats[0];
        // }
      }
    );
  }

  openPrivacy() {
    window.open(environment.privacyUrl, "_blank");
  }
  openHelp() {
    window.open(environment.helpUrl, "_blank");
  }
  setActiveChat(buttonIndex: number, key: string): void {
    // @ts-ignore
    // set active chat by index
    this.activeChat = this.sideChatBox.get(key)[buttonIndex];
    if (this.projectData)
      this.chatService.setActiveChat(`${this.projectData.serverPath.setActiveChat}/${this.activeChat.id}`)
    this.newChat = 0;
    if (window.innerWidth <= 1200) {
      this.openSidebar = false;
    }
  }
  editChat(chat: Chat) {
    this.isEditing = chat.id;
    this.oldTitle = chat.title;
  }
  async removeChat(chat: Chat) {
    if (this.projectData) {
      await this.chatService.removeChat(chat, this.projectData.serverPath.removeChat).subscribe(async (res) => { }, (err) => { console.log('e', err) })
      this.chats = await this.chats.filter((obj: any) => { return obj.id !== chat.id; });
      this.sideChatBox = await this.mapArrayByCreatedAt(this.chats);
      await this.chatService.setActiveChat(`${this.projectData.serverPath.setActiveChat}/${this.chats[0].id}`)
      this.activeChat = this.chats[0];
      await this.chatService.setActiveChat(`${this.projectData.serverPath.setActiveChat}/${this.chats[0].id}`)
      this.activeChat = this.chats[0];
    }
  }


  async setChatTitle(chat: Chat) {
    this.isEditing = -1;
    if (chat.title !== this.oldTitle) {
      if (chat.title.toString().length > 0) {
        let res;
        if (this.projectData)
          res = await this.chatService.editChat(chat, this.projectData.serverPath.editChat);
        if (!res) {
          chat.title = this.oldTitle;
        }
      } else {
        chat.title = this.oldTitle;
      }
    }
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.endScroll = true;
    } else {
      this.endScroll = false;
    }
  }
  setActiveNewChatBtn() {
    // activate new chat and set active chat to null
    this.chatService.setIsNewChat(true)
    this.chatService.conversationId='';
    this.chatService.context=undefined
    this.newChat = 1;

  }

  isDarkMode() {
    return this.darkModeService.isDarkMode && this.router.url != '/';
  }

  getTimeAgo(dateString: string): string {
    const currentDate = new Date();
    const inputDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return 'Today:';
    } else if (daysDifference === 1) {
      return 'Yesterday:';
    } else if (daysDifference > 1 && daysDifference <= 7) {
      return 'Previous 7 Days:';
    } else if (daysDifference > 7 && daysDifference <= 30) {
      return 'More than a 2 weeks ago:';
    } else if (daysDifference > 30 && daysDifference <= 60) {
      return 'Last month:';
    } else {
      return 'Last:';
    }
  }

  // mapping all chats by created_at field
  async mapArrayByCreatedAt(array: Chat[]): Promise<Map<string, Chat[]>> {
    const sortArray = array?.sort((a: any, b: any) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    })
    return sortArray?.reduce((acc, obj) => {
      const createdAtKey = this.getTimeAgo(obj.createdAt);
      if (acc.has(createdAtKey)) {
        acc.get(createdAtKey)!.push(obj);
      } else {
        acc.set(createdAtKey, [obj]);
      }
      return acc;
    }, new Map<string, Chat[]>());
  }
  truncateTitle(originalTitle: string, maxWords: number = 8): string {
    const words = originalTitle.split(' ');
    const truncatedTitle = words.slice(0, maxWords).join(' ');
    if (words.length > maxWords) {
      return `${truncatedTitle} ...`;
    }
    return truncatedTitle;
  }

  setOpenSetting() {
    this.openSetting = !this.openSetting;
  }

  logout(): void {
    this.authorizationService.logout();
    this.router.navigate(['/']);
  }
  toggleDarkMode() {
    this.darkModeService.toggleDarkMode();
    this.isDarkModeCheck = this.darkModeService.isDarkMode;
  }

  originalOrder = (a: any, b: any): number => {
    return 0;
  }
  async changeProcedure(title: string) {
    this.chats[0].title = title;
    this.sideChatBox = await this.mapArrayByCreatedAt(this.chats)
  }

  changeTitle() {
    this.chatService.changeTitle.subscribe(async (title: string) => {
      this.chats[0].title = title;
      this.sideChatBox = await this.mapArrayByCreatedAt(this.chats)
    })
  }
  saveToLocalStorage(){
    if(this.numberOfResearch > 50)
       this.numberOfResearch = 50
      else if(this.numberOfResearch == null || this.numberOfResearch ==0){
        this.numberOfResearch = 10
      }
    localStorage.setItem('numberOfResearch', this.numberOfResearch.toString());
  }
}
