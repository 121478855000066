import { Injectable } from '@angular/core';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  public analytics:any;
  constructor() { }
  init() {
    if (localStorage.getItem('sso.idtoken') == null) {
      const app = initializeApp(environment.ProjectsEnvironment.portalEnvironment.firebaseConfig);
      this.analytics = getAnalytics(app);
    }
  }
  logEvent(logName:string ) {
    const analytics = getAnalytics();
    logEvent(analytics,logName,{});
  }
}
