export default interface Message{
  id: number,
  role: string,
  content: string,
  time: string
}

export interface Context{
  Name:string,
  DocId: string
}

export interface TableSurce{
  Name:string,
  DocId: string,
  BU:string,
  Author: string,
  checked?:boolean
}