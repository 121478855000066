import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';
import { ProjectsDataService } from '../services/projectsData.service';
@Injectable({
  providedIn: 'root',
})
export class CheckRoleGuard implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private projectDataService: ProjectsDataService
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this.authorizationService.Config(route.data['env']);
    if (this.authorizationService.isLoggedInNewClientId())
      if (this.authorizationService.isLoggedIn(route.data['env'].clientId))
        return true;
      else {
        if (route.routeConfig?.path != 'launchpad') {
          route.routeConfig?.path ? localStorage.setItem('path', `/${route.routeConfig?.path}`) : null;
          let projectData = this.projectDataService.findProjectByProp(
            'path',
            `/${route.routeConfig?.path}`
          );
          if (projectData) {
            localStorage.setItem('rolesDisplay', projectData.rolesDisplay);
            this.router.navigateByUrl('splash');
          }
        }
        return true;
      }
    else {
      // this.router.navigateByUrl('launchpad');
      route.routeConfig?.path ? localStorage.setItem('path', `/${route.routeConfig?.path}`) : null;
      let projectData = this.projectDataService.findProjectByProp(
        'path',
        `/${route.routeConfig?.path}`
      );
      if (projectData) {
        localStorage.setItem('rolesDisplay', projectData.rolesDisplay);
        this.router.navigateByUrl('splash');
      }
      this.router.navigateByUrl('splash');
      return false;
    }

  }
}