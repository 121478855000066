import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChatWindowComponent } from './components/chat-window/chat-window.component';
import { UserInputComponent } from './components/user-input/user-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpHandler } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { HeaderComponent } from './components/header/header.component';
import { PostauthComponent } from './components/postauth/postauth.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { typewriter }  from './pipe/typewriter.pipe';
import { ClickOutsideDirective } from './Directive/ClickOutsideDirective';
import {MatMenuModule} from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { TypingEffectPipe } from './pipe/typingEffect';
import { TypingAnimationDirective } from './Directive/typing-animation.directive';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthorizationService } from './services/authorization.service';
import { JWT_OPTIONS, JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { FirebaseService } from './services/firebase.service';
import { LaunchpadComponent } from './components/launchpad/launchpad.component';
import { ProceduresBotsComponent } from './components/procedures-bots/procedures-bots.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SplashComponent } from './components/splash/splash.component';
import { TableComponent } from './components/shared-component/table/table.component';
import { AiServices } from './services/ai-services.service';
import { OpeningScreenComponent } from './components/shared-component/opening-screen/opening-screen.component';


@NgModule({
  declarations: [
    AppComponent,
    ChatWindowComponent,
    UserInputComponent,
    HeaderComponent,
    PostauthComponent,
    SideBarComponent,
    typewriter,
    ClickOutsideDirective,
    TypingEffectPipe,
    TypingAnimationDirective,
    LaunchpadComponent,
    ProceduresBotsComponent,
    PageNotFoundComponent,
    SplashComponent,
    TableComponent,
    OpeningScreenComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatIconModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    OAuthModule.forRoot(),
    JwtModule
  ],
  providers: [
    AuthorizationService,
    AiServices,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    FirebaseService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
