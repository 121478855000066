// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  httpUrl:"https://api.ai-services-staging.appsb.icldig.icl-group.com/",
  // httpUrl: 'http://localhost:3000/',
  helpUrl: 'https://forms.office.com/r/z4zU70HtpX',
  version:"1.2.8",
  privacyUrl: 'https://icl-group-sustainability.co.il/privacy-policy/',
  ProjectsEnvironment:{
    "portalEnvironment":{
      authConfig: {
        clientId: '933bec2b-957f-49c4-a7ae-d825f8a40ea0',
        issuer:
          'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
        strictDiscoveryDocumentValidation: false,
        responseType: 'id_token token',
        scope: '933bec2b-957f-49c4-a7ae-d825f8a40ea0/.default',
        AlwaysIncludeuserClaimsInIdToken: true,
        route:'',

      },
      firebaseConfig: {
        apiKey: 'AIzaSyBWIzuKH_TqjhGqwTLeMp6FpdIFZaJeqE4',
        authDomain: 'icl-chatgpt.firebaseapp.com',
        projectId: 'icl-chatgpt',
        storageBucket: 'icl-chatgpt.appspot.com',
        messagingSenderId: '144560409463',
        appId: '1:144560409463:web:0f4cbb4760469c2fdf6f72',
        measurementId: 'G-LH89NT85FP',
      },
    },
    "chatGPTEnvironment":{
      authConfig: {
        clientId: 'cf6e84fd-5fe0-472a-85c0-671a1435f14c',
        issuer:
          'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
        strictDiscoveryDocumentValidation: false,
        responseType: 'id_token token',
        scope: 'cf6e84fd-5fe0-472a-85c0-671a1435f14c/.default',
        AlwaysIncludeuserClaimsInIdToken: true,
        route:'chat-message',
      }
    }, 
    "procedureBotsEnvirpnment":{
      authConfig: {
        // clientId: 'aeb8db83-d6cd-432e-b8e0-ddfed81d48a8',
        clientId: '933bec2b-957f-49c4-a7ae-d825f8a40ea0',
        issuer:
          'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
        strictDiscoveryDocumentValidation: false,
        responseType: 'id_token token',
        // scope: 'aeb8db83-d6cd-432e-b8e0-ddfed81d48a8/.default',
        scope: '933bec2b-957f-49c4-a7ae-d825f8a40ea0/.default',
        AlwaysIncludeuserClaimsInIdToken: true,
        route:'procedures-bots',
      }
    },
    "researchBotEnvirpnment":{
      authConfig: {
        clientId: '49460125-64d2-4b4c-aa84-62cb5f928716',
        issuer:
          'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
        strictDiscoveryDocumentValidation: false,
        responseType: 'id_token token',
        scope: '49460125-64d2-4b4c-aa84-62cb5f928716/.default',
        AlwaysIncludeuserClaimsInIdToken: true,
        route:'research-bot',
      }
    },
    "rotemEngineeringBotEnvirpnment":{
      authConfig: {
        clientId: '2dda820e-54d0-4d71-9545-e347293f4ea8',
        issuer:
          'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
        strictDiscoveryDocumentValidation: false,
        responseType: 'id_token token',
        scope: '2dda820e-54d0-4d71-9545-e347293f4ea8/.default',
        AlwaysIncludeuserClaimsInIdToken: true,
        route:'rotem-engineering-bot',
      }
    },
    "agronomistBotEnvirpnment":{
      authConfig: {
        clientId: '2c5d0590-04a6-472e-8aae-1bfca3f4ac10',
        issuer:
          'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
        strictDiscoveryDocumentValidation: false,
        responseType: 'id_token token',
        scope: '2c5d0590-04a6-472e-8aae-1bfca3f4ac10/.default',
        AlwaysIncludeuserClaimsInIdToken: true,
        route:'agronomist-bot',
      }
    },
    "patentBotEnvirpnment":{
      authConfig: {
        clientId: '3ff54711-8bd4-4afb-9ec8-8822a4c6e257',
        issuer:
          'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
        strictDiscoveryDocumentValidation: false,
        responseType: 'id_token token',
        scope: '3ff54711-8bd4-4afb-9ec8-8822a4c6e257/.default',
        AlwaysIncludeuserClaimsInIdToken: true,
        route:'patent-bot',
      }
    },
    "fieldTrailChatEnvirpnment":{
      authConfig: {
        clientId: '134c4d7d-6cd4-4a42-94a7-4ad2f2f58c91',
        issuer:
          'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
        strictDiscoveryDocumentValidation: false,
        responseType: 'id_token token',
        scope: '134c4d7d-6cd4-4a42-94a7-4ad2f2f58c91/.default',
        AlwaysIncludeuserClaimsInIdToken: true,
        route:'field-trail-chat',
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
