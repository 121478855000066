<!-- <div class="body">
    <app-header ></app-header>
        <div class="row machins-row">
            <ng-container *ngFor="let project of projectList">
                <div *ngIf="display(project.rolesDisplay)" class="machine-container border-graided">
                    <button  (click)="routers(project.path)" class=" btn btn-icon" type="button">
                        <div class=" main-logo"><img class="machine-icon" src="../../../assets/images/{{project.icon}}"></div>
                        <label class="machine-title">{{project.title}}</label>
                    </button>
                </div>
            </ng-container>
            
        </div> 
</div> -->

<div class="splash-container-rap ">
    <div class="header-icon">
        <img *ngIf="isDarkModeCheck === false" src="./assets/images/logo-in.png" class="ChatGPT-logo" alt="icl-logo" />
        <img *ngIf="isDarkModeCheck" src="./assets/images/logo-in-d.png" class="ChatGPT-logo" alt="icl-logo" />
        </div>
    <div class="splash-container"></div>
    <div class="content-rap">
        <div class="top-container">
            <h1>
                Welcome to ICL <img src="../../../assets/aiservices.png" alt="AI" class="ai-logo-header"> Portal
            </h1>
            <p>All AI services available to you, Enjoy!</p>
        </div>
        <div class=" machins-row">
            <ng-container *ngFor="let project of projectList">
                <div *ngIf="display(project.rolesDisplay)" class=" col machine-container border-graided">
                    <button (click)="routers(project)" class=" btn btn-icon" type="button">
                        <div class=" main-logo"><img class="machine-icon" src="../../../assets/images/{{project.icon}}">
                        </div>
                        <label class="machine-title">{{project.title}}</label>
                        <br>
                        <label>{{project.version}}</label>
                    </button>
                </div>
            </ng-container>

        </div>
    </div>
    <div class=" footer ">
        <button class="btn-rm btn-model">
            <div class="img-text">
                <img class="dark-mod-img"
                    [src]="isDarkModeCheck? '../../../assets/icons/moon-w.svg' : '../../../assets/icons/moon.svg'"
                    width="24" height="24" />
                <div class="text-btn">Use dark theme</div>
            </div>
            <label class="switch">
                <input type="checkbox" id="darkModeSwitch" [checked]="isDarkModeCheck" (change)="toggleDarkMode()" />
                <span class="slider round"></span>
            </label>
        </button>
        <div class="version">Version {{version}}</div>
    </div>
</div>