import { Component } from '@angular/core';
import { DarkModeService } from './services/dark-mode.service';
import { FirebaseService } from './services/firebase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ICL-ChatGPT';
  constructor(private darkModeService: DarkModeService,private firebaseService:FirebaseService,private router:Router){
    this.firebaseService.init();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let token = urlParams.get('token');
    urlParams.has('token') ? localStorage.setItem('sso.idtoken', token ? token : "") : '';
  }

  get isDarkMode() {
    return this.router.url.split('?')[0] !='/' && this.darkModeService.isDarkMode;
  }
}
