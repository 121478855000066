import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { JwtHelperService } from '@auth0/angular-jwt';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private token?: string | null = null;
  public userInfo: any;
  private authConfig: any;
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private jwtHelper: JwtHelperService
  ) {}
  public async initAuthConfig(authConfig: any) {
    try {
      this.authConfig = authConfig;
      this.oauthService.configure({
        ...authConfig,
        redirectUri: window.location.origin + '/postauth',
      });
      this.oauthService.setStorage(localStorage);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();
      this.oauthService.setupAutomaticSilentRefresh();
    } catch {
      console.log('error in login init');
    }
  }
  public isLoggedIn(clientId: string):boolean {
    type tocken = {
      aud: string;
      exp: any;
    };
    if (localStorage.getItem('sso.idtoken') != null) return true;
    let token = this.getToken();
    if (!token) {
      return false;
    } else {
      let decodedToken: tocken = jwt_decode(token);
      let actualAudience = decodedToken.aud;
      let expirationTime = decodedToken.exp;
      const currentTimeInSeconds = Math.floor(Date.now() / 1000);

      if (actualAudience === clientId) {
        if (expirationTime && expirationTime > currentTimeInSeconds) {
          return true;
        } else {
          console.error('Token is expired.');
          return false;
        }
      } else {
        console.log('Token audience is not valid.');
        return false;
      }
    }
  }
  async login(path, clientId: string) {
    if (await this.isLoggedIn(clientId)) {
      return;
    } else {
      localStorage.removeItem('sso.idtoken');
      await this.oauthService.loadDiscoveryDocumentAndTryLogin()
        .then((isLoggedIn: boolean) => {
          if (isLoggedIn) {
            this.oauthService.setupAutomaticSilentRefresh();
            setTimeout(() => {
              return
                        }, 2000);
          } else {
            this.oauthService.initImplicitFlow();
            // this.router.navigate([path]);
          }
        });
    }
  }
  async loginWithNewClientId(authConfig: any){
    authConfig.redirectUri=window.location.origin + '/splash'
    this.oauthService.configure({
      ...authConfig,
    });
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
   await this.oauthService.loadDiscoveryDocumentAndTryLogin().then((isLoggedIn: boolean) => {
      if (isLoggedIn) {
        this.oauthService.setupAutomaticSilentRefresh();
      } else {
        this.oauthService.initImplicitFlow();
      }
    });;

  }
  public logout(): void {
    this.oauthService.logOut();
  }

  public getToken(): string | null | undefined {
    if (localStorage.getItem('sso.idtoken') != null) {
      this.token = localStorage.getItem('sso.idtoken');
    } else this.token = this.oauthService.getAccessToken();
    return this.token;
  }

  public getUserName() {
    const accessToken = this.oauthService.getAccessToken();
    if (accessToken) {
      // Decode the JWT token to get user information
      const decodedToken = this.jwtHelper.decodeToken(accessToken);
      return decodedToken.name;
    }
  }
  getDecoddedTocken() {
    type token = {
      aud: string;
      exp: any;
    };
    let token = this.getToken();
    if (!token) {
      return new Error('Auth header is not provided');
    }
    let decodedToken: token = jwt_decode(token);
    let actualAudience = decodedToken.aud;
    let expirationTime = decodedToken.exp;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    if (expirationTime && expirationTime > currentTimeInSeconds) {
      return decodedToken;
    } else {
      return new Error('Auth header is expired');
    }
  }
  isLoggedInNewClientId(){
     let islogin=this.oauthService.hasValidAccessToken();
     return islogin
  }
  Config(authConfig){
    authConfig.redirectUri=window.location.origin + '/splash'
    this.oauthService.configure({
      ...authConfig,
    });
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
  }
}
