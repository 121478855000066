import { Component, OnInit } from '@angular/core';
import { RouteInfo } from 'src/app/classes/enum/interfaces/RoutInfo';
import { ProjectRole } from 'src/app/classes/enum/role';
import { ProjectsDataService } from 'src/app/services/projectsData.service';

@Component({
  selector: 'app-opening-screen',
  templateUrl: './opening-screen.component.html',
  styleUrls: ['./opening-screen.component.scss']
})
export class OpeningScreenComponent implements OnInit {

  ProjectRole = ProjectRole;
  role: ProjectRole;
  projectData: RouteInfo | undefined;

  constructor(private projectDataService : ProjectsDataService) {
    const storedRole = localStorage.getItem('rolesDisplay');
    if (storedRole && Object.values(ProjectRole).includes(storedRole as ProjectRole))
      this.role = storedRole as ProjectRole;
    // this.projectRole = localStorage.getItem('rolesDisplay') as ProjectRole;
    this.projectData = this.projectDataService.findProjectByProp(
      'rolesDisplay',
      this.role
    );
   }

  ngOnInit(): void {
  }

}
