import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { AuthorizationService } from './authorization.service';
import { ProjectRole } from '../classes/enum/role';
@Injectable({
  providedIn: 'root'
})
export class HttpHandlerService {
  private baseUri = environment.httpUrl;
  
  constructor(private http: HttpClient, private msalLoginService:AuthorizationService) {
  }

  protected buildHeaders(inHeaders: any = {}): HttpHeaders {
    let ret = new HttpHeaders();
    Object.assign(inHeaders, { 'Content-Type': 'application/json' })
    Object.assign(inHeaders, { authorization: `Bearer ${this.msalLoginService.getToken()}` })
    Object.keys(inHeaders).map(e => {
      ret = ret.append(e, inHeaders[e]);
    })
    return ret;
  }

  get<T>(url: string, data?: {}): Observable<T> | unknown {
    const options: { headers?: HttpHeaders | { [header: string]: string | string[]; }; params?: HttpParams | { [param: string]: string | string[]; } } = { headers: this.buildHeaders() };
    if (typeof data !== 'undefined') {
      options.params = new HttpParams({ fromObject: data as { [param: string]: string } });
    }
    return this.http.get<T>(`${this.baseUri}${url}`, options);
  }


  post<T>(url: string, data: {}): Observable<T> {
    return this.http.post<T>(this.baseUri + url, data, { headers: this.buildHeaders() })
  }
  put<T>(url: string, data: {}): Observable<T> {
    const options: { headers?: HttpHeaders | { [header: string]: string | string[]; }; params?: HttpParams | { [param: string]: string | string[]; } } = { headers: this.buildHeaders() };
    return this.http.put<T>(`${this.baseUri}${url}`, data, options)
      .pipe(
        catchError((error: any) => {
          console.error('HTTP Error:', error);
          throw error; // Rethrow the error to be caught by the component
        })
      );
  }
  delete<T>(url: string): Observable<T> {
    const options: { headers?: HttpHeaders | { [header: string]: string | string[]; }; params?: HttpParams | { [param: string]: string | string[]; } } = { headers: this.buildHeaders() };
    return this.http.delete<T>(`${this.baseUri}${url}`, options)
      // .pipe(
      //   catchError((error: any) => {
      //     console.error('HTTP Error:', error);
      //     throw error; // Rethrow the error to be caught by the component
      //   })
      // );
  }
}
