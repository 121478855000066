import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
  constructor( private authorizationService: AuthorizationService,
    private router: Router,) {}

  async isLoggedIn(envConfig): Promise<boolean> {
    this.authorizationService.Config(envConfig);
    return await this.authorizationService.isLoggedIn(envConfig.clientId);
  }

  async ngOnInit(): Promise<void> {
    let path = localStorage.getItem('path');
    let envConfig = this.switsAuthConfig(path);
    if (await this.isLoggedIn(envConfig)) {
      try {
        if (path !== null) {
          // localStorage.removeItem('path');
          this.router.navigateByUrl(path.replace('/', ''));
        }
      } catch (error) {
        console.error(error);
      }
    } else {
     await this.authorizationService.login(path, envConfig.clientId).then(async ()=>{
        if(await this.authorizationService.isLoggedInNewClientId())
        this.router.navigateByUrl(path!.replace('/', ''));      });
    }
  }

  switsAuthConfig(path) {
    let envConfig;
    switch (path!.replace('/', '')) {
      case 'chat-message':
        envConfig =
          environment.ProjectsEnvironment.chatGPTEnvironment.authConfig;
        break;
      case 'procedures-bots':
        envConfig =
          environment.ProjectsEnvironment.portalEnvironment.authConfig;
        break;
      case 'research-bot':
        envConfig =
          environment.ProjectsEnvironment.researchBotEnvirpnment.authConfig;
        break;
      case 'rotem-engineering-bot':
        envConfig =
          environment.ProjectsEnvironment.rotemEngineeringBotEnvirpnment.authConfig;
        break;
      case 'agronomist-bot':
        envConfig =
          environment.ProjectsEnvironment.agronomistBotEnvirpnment.authConfig;
        break;
      case 'patent-bot':
        envConfig =
          environment.ProjectsEnvironment.patentBotEnvirpnment.authConfig;
        break;
      case 'field-trial-chat':
        envConfig =
          environment.ProjectsEnvironment.fieldTrailChatEnvirpnment.authConfig;
        break;
        case 'internal-audit-chat':
          envConfig =
            environment.ProjectsEnvironment.InternalAuditChatEnvirpnment.authConfig;
          break;
        case 'it-legal-chat':
          envConfig =
            environment.ProjectsEnvironment.ITLegalChatEnvirpnment.authConfig;
          break;
        case 'the-mind':
          envConfig =
            environment.ProjectsEnvironment.TheMindEnvirpnment.authConfig;
          break;
      default:
        localStorage.setItem('path', 'launchpad');
        envConfig =
          environment.ProjectsEnvironment.portalEnvironment.authConfig;
        break;
    }
    return envConfig;
  }
}
