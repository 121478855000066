import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteInfo } from 'src/app/classes/enum/interfaces/RoutInfo';
import { ProjectRole } from 'src/app/classes/enum/role';
import { AuthorizationService } from 'src/app/services/authorization.service';
import { DarkModeService } from 'src/app/services/dark-mode.service';
import {
  ProjectsDataService,
} from 'src/app/services/projectsData.service';
import { RolesService } from 'src/app/services/roles.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-launchpad',
  templateUrl: './launchpad.component.html',
  styleUrls: ['./launchpad.component.scss'],
})
export class LaunchpadComponent implements OnInit {
  isDarkModeCheck: boolean | null =
    this.darkModeService.isDarkMode && this.router.url != '/';
  projectList: RouteInfo[] = [];
  version: any = environment.version;
  constructor(
    private rolesServices: RolesService,
    private router: Router,
    private darkModeService: DarkModeService,
    private projectDataService: ProjectsDataService,
    private authorizationService: AuthorizationService
  ) { }
  roles: string[] = [];
  async ngOnInit(): Promise<void> {
    let envConfig =
      environment.ProjectsEnvironment.portalEnvironment.authConfig;
    if (await this.authorizationService.isLoggedIn(envConfig.clientId)) {
    } else {
      this.router.navigateByUrl('postauth');
    }
    this.roles = this.rolesServices.getRoles();
    console.log("role in lunchpad: ", this.roles)
    this.projectList = this.projectDataService.getAllData();
  }
  display(projectRole: string) {
    return this.roles.includes(projectRole);
  }
  async routers(project: RouteInfo) {
    localStorage.setItem('path', project.path);
    localStorage.setItem('rolesDisplay', project.rolesDisplay);
    // this.projectDataService.setProjectRole(project.rolesDisplay);
    let environmentName: keyof typeof environment.ProjectsEnvironment =
      project.environment;
    let config = environment.ProjectsEnvironment[environmentName].authConfig;
    this.authorizationService.initAuthConfig(config);
    if (await this.authorizationService.isLoggedIn(config.clientId)) {
      this.router.navigate([project.path]);
    } else {
      this.authorizationService.logout();
      this.authorizationService.loginWithNewClientId(config);
    }
  }
  toggleDarkMode() {
    this.darkModeService.toggleDarkMode();
    this.isDarkModeCheck = this.darkModeService.isDarkMode;
  }
}
