import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procedures-bots',
  templateUrl: './procedures-bots.component.html',
  styleUrls: ['./procedures-bots.component.scss']
})
export class ProceduresBotsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
