import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  isDarkMode = this.setDarkMood();

  constructor(@Inject(DOCUMENT) private document: Document,private router:Router) {
    // Read the mode preference from local storage
    const storedMode = localStorage.getItem('darkMode');
    this.isDarkMode = storedMode === 'enabled';
    this.setClass()
  }
  setDarkMood(){
    const storedMode = localStorage.getItem('darkMode');
    this.setClass();
    return this.isDarkMode = storedMode === 'enabled';
  }
  setClass(){
    if(this.isDarkMode && this.router.url.split('?')[0] !='/'){
      this.document.body.classList.add('dark-mode');
    }else{
      this.document.body.classList.remove('dark-mode');
    }

  }  
  toggleDarkMode() {
    this.isDarkMode = !this.isDarkMode;
    this.setClass()
    // Save the mode preference in local storage
    localStorage.setItem('darkMode', this.isDarkMode ? 'enabled' : 'disabled');
  }
}