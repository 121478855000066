<div
[ngClass]="{ 'hebrew-font': hebrew }"
  class=" user-input absolute bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient  md:!bg-transparent dark:md:bg-vert-dark-gradient pt-2 md:pl-2 md:w-[calc(100%-.5rem)]">
  <form [formGroup]="formData"
  [ngClass]="{'dir-defult': hebrew }"
    class=" stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl ">
      <button [ngClass]="{'m-left': hebrew }"  class="stop-generating"  *ngIf="messageSend" (click)="stopWrit()">
      <img [src]="!isDarkMode ? 'assets/icons/check-square-contained.svg' : 'assets/icons/check-square-contained-w.svg'"
        class="img-dot" />
      <span>{{projectData?.stopButton}}</span>
    </button>
    <div class="relative-flex relative flex h-full flex-1 items-stretch md:flex-col">
      <div
        class="flex flex-col w-full py-[10px] flex-grow md:py-4 md:pl-4 relative border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-xl shadow-xs dark:shadow-xs">
        <!-- <div class="h-full">
        </div> -->

      </div>
      <div class="textarea-input flex flex-col w-full"
      [ngClass]="{'inputHebrew dir-he': hebrew}">
        <textarea *ngIf="!hebrew"  formControlName="userInput" placeholder="Send a message..." (keyup.enter)="sendUserInput()"  (keyup)="autoGrowTextZone($event)"
          (keydown)="autoGrowTextZone($event)" ></textarea>
          <textarea *ngIf="hebrew" formControlName="userInput" placeholder="שליחת הודעה" (keyup.enter)="sendUserInput()"  (keyup)="autoGrowTextZone($event)"
          (keydown)="autoGrowTextZone($event)" ></textarea>
        <button [ngClass]="{'buttonHebrew': hebrew}" mat-icon-button (click)="sendUserInput()" [disabled]="this.formData.controls['userInput'].value.trim() === ''">
          <mat-icon [style]="!messageValidate &&  innerMObile  ? 'color: #00B5D4 !important;' : 'null'"  [ngClass]="{'sendHebrew': hebrew }" *ngIf="!messageSend">send</mat-icon>
          <img src="assets/icons/typing-dots.gif" *ngIf="messageSend" class="typing-dot" />
        </button>
          <button *ngIf="role==ProjectRole.patentBot" class="but_icon" (click)="handleClick()">
            <mat-icon class="attach">attach_file</mat-icon>
          </button>
          <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)" accept=".csv, .xlsx" />
      </div>
    </div>
  </form>
</div>
<div  class="sc-help">
  <div class="model-help" *ngIf="ModelHelp">
    <button class="btn-rm a-help" (click)="openHelp()">
      Help & FAQ
    </button>
    <!-- <a class="a-help" target="_blank"  href="https://icl-group-sustainability.com/reports/awards-and-recognition/">
      Report
    </a> -->
    <a class="a-help" target="_blank" (click)="openPrivacy()">
      Privacy
    </a>
  </div>
  <button class=" help-btn btn-rm" (click)="openModelHelp()">
    <img #toggleButtonHelp class="toggleButtonHelp"
      [src]="!isDarkMode ? '../../../assets/icons/help.svg' : '../../../assets/icons/help-w.svg'" width="24"
      height="24" />
  </button>
</div>